import React from "react"
import Banner from "../containers/Corporate/Banner"
import HeaderMenu from "../containers/Corporate/HeaderMenu"
import AboutSection from "../containers/Corporate/About"
import NewsLetter from "../containers/Corporate/SubscribeLetter"
import Footer from "../containers/Corporate/Footer"
import GlobalStyle from "../containers/Corporate/Common/global-styles"
import "../components/layout.css"
import SEO from "../components/seo"
import Fonts from "../containers/Corporate/Common/fonts"
import Pricing from "../containers/Saas2/Pricing"

const Corporate = () => (
  <div>
    <Fonts />
    <GlobalStyle />
    <HeaderMenu />

    <main>
      <SEO
        title="Greenlight Health - Launching Soon"
        description="COVID-19 Testing and Operations"
      />
      <Banner />
      <AboutSection />
      <Pricing />
      <NewsLetter />
    </main>

    <Footer />
  </div>
)

export default Corporate
